<template>
  <router-view/>
</template>
<!--  <tab-detail-page :menus="menus"/>-->

<script>
// import TabDetailPage from '@/components/layout/TabDetailPage';

export default {
  name: 'Overview',
  // components: { TabDetailPage },
  data() {
    return {
      menus: [
        {
          id: 1,
          title: '观看数据',
          path: '/index/data-statistics/broadcast/query/overview/watch',
        },
        {
          id: 2,
          title: '直播热度数据',
          path: '/index/data-statistics/broadcast/query/overview/heat',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">

</style>
