<script>

import { downloadRetry } from '../api'

export default {
  name: 'DownloadReasonModal',
  data() {
    return {
      shown: false,
      loading: false,

      id: '',
      title: '导出',

      disabledFormat: false,

      params: {},
      form: {
        down_type: 2, // 1申请明文下载 2密文下载
        reason_application: ''
      },

      rules: {
        down_type: [{ required: true, trigger: 'blur', message: '请选额导出格式' }],
        reason_application: [{
          required: true, validator: (rule, value, callback) => {
            if(this.form.down_type === 1 && !value) {
              callback(new Error('请填写申请原因'))
            } else {
              callback()
            }
          }, trigger: 'blur', message: '请填写申请原因'
        }]
      }
    }
  },
  methods: {
    // 会被外部调用
    show({ id, down_type, reason_application, disabledFormat, params } = {}) {
      this.id = id
      this.disabledFormat = disabledFormat || false

      this.params = params || {}
      this.form.down_type = down_type || 2
      this.form.reason_application = reason_application || ''

      this.shown = true

      if(this.disabledFormat) {
        this.$nextTick(() => {
          this.$refs.reasonInputRef?.focus()
        })
      }
    },
    // 会被外部调用
    stopLoading() {
      this.loading = false
    },
    // 会被外部调用
    close() {
      this.onClose()
    },
    onClose() {
      this.shown = false
    },
    afterClosed() {
      this.loading = false

      this.id = ''
      this.disabledFormat = false
      this.form = {
        down_type: 2,
        reason_application: ''
      }
    },

    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((validated) => {
          if(validated) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })
    },
    onSubmit() {
      this.validate().then(() => {
        if(this.id) {
          this.onSave()
        } else {
          this.loading = true
          this.$emit('saved', { ...this.form, ...this.params })
        }
      })
    },
    async onSave() {
      if(this.loading) {
        return
      }
      this.loading = true

      const params = {
        log_id: this.id,
        ...this.form
      }

      const data = await downloadRetry(params).finally(() => {
        this.loading = false
      })
      if(!data || data.error_code) {
        this.$message.error(data?.message || '保存失败')
        return
      }
      this.$message.success(data.message || '保存成功')

      this.$emit('saved', { ...params, ...this.params })
      this.onClose()
    }
  }
}

</script>

<template>
  <a-modal :visible="shown"
           :title="title"
           :confirm-loading="loading"
           :mask-closable="true"
           :centered="true"
           :footer="null"
           width="500px"
           :after-close="afterClosed"
           @cancel="onClose()">

    <a-form-model ref="formRef"
                  :model="form"
                  :rules="rules"
                  :label-col="{span: 4}"
                  :wrapper-col="{span: 18}"
                  label-align="left"
                  class="form-model flex-grow"
                  @submit="onSubmit">
      <a-form-model-item label="导出格式" prop="down_type" :colon="false" class="form-item">
        <a-radio-group v-model="form.down_type" :disabled="loading || disabledFormat">
          <a-radio :value="2">密文</a-radio>
          <a-radio :value="1">明文</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-show="form.down_type === 1"
                         label="申请原因"
                         prop="reason_application"
                         :colon="false"
                         :label-col="{span: 24}"
                         :wrapper-col="{span: 24}"
                         class="form-item">
        <a-textarea ref="reasonInputRef"
                    v-model="form.reason_application"
                    type="textarea"
                    :allow-clear="true"
                    :auto-size="{ minRows: 5, maxRows: 5 }"
                    :disabled="loading"
                    placeholder="请填写需要明文导出理由提交审核"
                    no-resize />
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{span: 24}" style="text-align: right; margin-bottom: 0;">
        <a-button type="outline" :loading="loading" @click="onClose()">取消</a-button>
        <a-button type="primary" :loading="loading" @click="onSubmit()">确定</a-button>
      </a-form-model-item>
    </a-form-model>

  </a-modal>
</template>

<style scoped lang="scss">

</style>
