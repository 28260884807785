<template>
  <div class="data-statistics-broadcast-detail">
    <div class="row row-left">
      <a-menu v-model="selectedMenu" mode="horizontal" @select="selectMenu">
        <a-menu-item v-for="item of menus" :key="item.id">
          {{ item.title }}
        </a-menu-item>
      </a-menu>
    </div>

    <router-view/>
  </div>
</template>

<script>
import {broadcastDetail} from '@/utils/Navs/DataStatisticsNavs';

export default {
  name: 'Detail',
  watch: {
    '$route.query.id'(id) {
      this.id = id || this.id;
    },
  },
  data() {
    return {
      titles: [{title: '直播统计'}, {title: '直播明细'}],
      menus: broadcastDetail,
      selectedMenu: ['1'],
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.executeSelection(to?.name || ''));
  },
  beforeRouteUpdate(to, from, next) {
    this.executeSelection(to?.name || '');
    next();
  },
  beforeDestroy() {
    this.$store.commit('Broadcast/updateUserName', '');
  },
  methods: {
    executeSelection(name) {
      this.menus.forEach(menu => {
        if (menu.name === name) {
          this.selectedMenu = [menu.id];
        }
      });
    },
    selectMenu({key}) {
      const path = this.getPath(key);
      this.$router.replace({path, query: {id: this.$route.query?.id}});
    },
    getPath(key) {
      const [{path}] = this.menus.filter(menu => menu.id === key + '' && menu.path);
      return path || '';
    },
  },
};
</script>

<style scoped lang="scss">
.data-statistics-broadcast-detail {
}
</style>
