<template>
  <div>
    <!--    <div class="pd-card">-->
    <!--      <a-collapse default-active-key="1" :bordered="false">-->
    <!--        <template #expandIcon="props">-->
    <!--          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0"/>-->
    <!--        </template>-->
    <!--        <a-collapse-panel key="1" :header="liveInfo.live_name" :style="customStyle">-->
    <!--          <a-space :size="50">-->
    <!--            <span>直播名称：{{ liveInfo.live_name || '' }}</span>-->
    <!--            <span>直播间ID：{{ liveInfo.live_id || '' }}</span>-->
    <!--            <span>送礼物人数：{{ liveInfo.giveGiftmanage_num || '' }}</span>-->
    <!--            <span>送礼物个数：{{ liveInfo.giveGiftNum || '' }}</span>-->
    <!--            <span>热度值：{{ liveInfo.live_heat_score_num || '' }}</span>-->
    <!--          </a-space>-->
    <!--        </a-collapse-panel>-->
    <!--      </a-collapse>-->
    <!--    </div>-->

    <div class="pd-card">
      <a-space :size="50" class="flex flex-start flex-wrap live-info">
        <span class="flex-none live-info-item">直播名称：{{ liveInfo.live_name || '' }}</span>
        <span class="flex-none live-info-item">直播间ID：{{ liveInfo.live_id || '' }}</span>
        <span class="flex-none live-info-item">送礼物人数：{{ liveInfo.giveGiftmanage_num || '' }}</span>
        <span class="flex-none live-info-item">送礼物个数：{{ liveInfo.giveGiftNum || '' }}</span>
<!--        <span class="flex-none live-info-item">热度值：{{ liveInfo.live_heat_score_num || '' }}</span>-->
      </a-space>
    </div>


    <div class="pd-card" style="text-align: right;">
      <a-button type="primary" :loading="downloading" class="mr-30" @click="exportExcel">导出</a-button>
      <a-button @click="back">返回</a-button>
    </div>

    <div class="pd-card">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  getBroadcastHeatDetailData,
  getBroadcastHeatDetailDataDownload,
} from '@/api/data-statistics/Index';
import Common from '@/utils/Common';

import Pagination, { getPagination } from '@/components/Pagination.vue';

export default {
  name: 'HeatDetail',
  components: { Pagination },
  data() {
    return {
      loading: false,
      downloading: false,

      liveId: 0,

      columns: [],
      data: [],
      pagination: getPagination(),

      liveInfo: {},
      customStyle: 'background: #f7f7f7; border-radius: 4px; margin-bottom: 5px; border: 0; overflow: hidden',
    };
  },
  created() {
    this.liveId = this.$route.query.id || 0;

    this.onSearch();
  },
  methods: {
    back() {
      this.$router.back();
    },

    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        page: page,
        per_page: pageSize,
        live_id: this.liveId,
      };
      if (this.loading || !this.liveId) {
        return;
      }
      this.loading = true;

      const data = await getBroadcastHeatDetailData(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = (data?.data || []).map((i, idx) => {
        return {
          ...i,
          id: i.id || `${this.pagination.current}-${idx}`,
        };
      });
      this.liveInfo = data?.meta?.live || {};
      const columns = data?.meta?.columns || [];
      columns.forEach((i) => {
        i.width = '120px';
        i.align = 'center';

        if (i.key === 'live_id') {
          i.width = '80px';
        }
        if (i.key === 'live_name') {
          i.width = '180px';
        }
      });
      this.columns = columns;

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    exportExcel() {
      if (this.downloading || !this.liveId) {
        return;
      }
      this.downloading = true;

      const params = {
        page: this.pagination.current,
        per_page: this.pagination.pageSize,
        live_id: this.liveId,
        is_export: 1,
      };
      getBroadcastHeatDetailDataDownload(params).then(() => {
        this.$message.success('导出成功');
      }).catch(() => {
        this.$message.error('导出失败');
      }).finally(() => {
        this.downloading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.live-info {
  width: 100%;
  padding: 10px;
  background: #F7F7F7;
  border-radius: 4px;
  margin-bottom: 5px;
  border: 0;
  overflow: hidden;
}
</style>
