<template>
  <a-select v-model="selectedIds"
            ref="selectRef"
            :allow-clear="true"
            :mode="mode"
            :show-search="true"
            :disabled="disabled"
            :auto-clear-search-value="false"
            :filter-option="false"
            :get-popup-container="getContainer"
            placeholder="请选择直播"
            class="select"
            @focus="resetSearch"
            @blur="resetSearch"
            @popupScroll="onScroll"
            @change="select"
            @search="onSearch">
    <a-select-option v-for="item of filteredOptions" :key="item.id" :value="item.id">
      <a-tooltip placement="right" :title="item.name">{{ item.name }}</a-tooltip>
    </a-select-option>
  </a-select>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectBroadcast',
  model: {
    prop: 'defaultValue',
    event: 'change',
  },
  props: {
    defaultValue: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    maxCount: { type: Number, default: 0 },
    mode: {
      type: String,
      default: 'multiple',
      validator: (val) => ['multiple', 'default', 'tags', 'combobox'].includes(val),
    },
  },
  computed: {
    ...mapState({
      options: state => state.Broadcast.liveList,
    }),
    getContainer() {
      // 1. 下拉框跟随输入框滚动
      // 2. 当用于 form-model-item 时，两个 $parent 保证 mode === 'multiple' 时，选中选项后，保证下拉框不消失
      return () => this.$refs.selectRef?.$parent?.$parent?.$el || this.$root?.$el || document.body || null;
    },
  },
  watch: {
    defaultValue() {
      this.setDefault();
      this.getFilteredOptions();
    },
    options() {
      this.setDefault();
      this.getFilteredOptions();
    },
  },
  data() {
    return {
      selectedIds: [],
      isAllClicked: false,

      page: 1,
      perPage: 50,
      filterTxt: '',
      filteredOptions: [],
    };
  },
  created() {
    if (!this.options?.length) {
      this.$store.dispatch('Broadcast/getBroadcastLiveList');
    }

    this.setDefault();
    this.getFilteredOptions();
  },
  methods: {
    resetSearch() {
      this.onSearch('');
    },
    onSearch(value) {
      this.filterTxt = value;
      this.page = 1;
      this.getFilteredOptions();
    },

    getFilteredOptions() {
      this.filteredOptions = this.options.filter((option, index) => {
        if (this.selectedIds.map(i => +i).indexOf(+option.id) > -1) {
          return true;
        }
        if (!this.filterTxt && index < this.page * this.perPage) {
          return true;
        }
        return this.filterTxt && option.name.indexOf(this.filterTxt) > -1;
      });
    },

    onScroll(ev) {
      const target = ev.currentTarget || ev.target || ev.srcElement;
      if (!target) {
        return;
      }

      const height = target.clientHeight || 250;
      const scrollTop = target.scrollTop || 0;
      const scrollHeight = target.scrollHeight || 250;

      if (scrollTop + 1.2 * height > scrollHeight && this.page * this.perPage < this.options.length) {
        this.loadMore();
      }
    },

    loadMore() {
      this.page += 1;
      this.getFilteredOptions();
    },

    selectAll() {
      this.isAllClicked = true;
    },

    setDefault() {
      if (!this.defaultValue) {
        this.ids = '';
        this.selectedIds = this.mode === 'multiple' ? [] : undefined;
        return;
      }
      const ids = this.defaultValue.split(',').map((i) => +i);
      this.selectedIds = this.mode === 'multiple' ? ids.map(i => +i) : ids[0];
    },

    select() {
      if (!this.options || !this.options.length) {
        return;
      }

      const selectedIds = this.mode === 'multiple' ? this.selectedIds : [this.selectedIds];

      if (this.mode === 'multiple' && this.maxCount && selectedIds.length > this.maxCount) {
        this.$message.info(`只允许最多选择${this.maxCount}个直播`);
        this.selectedIds.pop();
        return;
      }

      if (this.isAllClicked && this.mode === 'multiple') {
        this.selectedIds = this.options.map(op => op.id);
        this.selectedIds.unshift(0);
      }

      this.isAllClicked = false;

      const ids = selectedIds.filter(i => !!i).map((i) => +i);
      this.$emit('select', ids);
      this.$emit('change', ids.join(','));
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  min-width: 350px;
}
</style>
