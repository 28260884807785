<template>
  <div ref="lineBarRef" class="line-bar"></div>
</template>

<script>
import * as echarts from 'echarts';
import Common from '@/utils/Common';

const colors = ['#91CC75', '#EE6666', '#294AA5', '#FFFFFF', '#5470C6', '#91CC75', '#EE6666'];
const seriesLabel = (color, position) => ({
  show: true,
  position: position || 'top',
  valueAnimation: true,
  fontSize: 16,
  textBorderType: 'solid',
  textBorderWidth: 3,
  textBorderColor: '#FFFFFF',
  color,
});

export default {
  name: 'LineBar',
  props: {
    chartData: {type: Object, default: () => ({})},
  },
  watch: {
    chartData(data) {
      this.updateData(data);
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    setTimeout(() => this.initChart(), 0);
  },
  methods: {
    initChart() {
      const ref = this.$refs.lineBarRef;
      if (!ref) {return;}

      this.chart = echarts.init(ref);
      this.updateData(this.chartData);
    },

    updateData(data) {
      this.chart?.showLoading && this.chart.showLoading();

      const date = data?.date || [];
      const series = data?.series || [];

      series.forEach((i) => {
        if (i.name.includes('时长')) {
          i.type = 'line';
          i.yAxisIndex = 0;
          if (i.name.includes('直播')) {
            i.color = colors[0];
            i.label = seriesLabel(i.color, 'left');
          } else {
            i.color = colors[1];
            i.label = seriesLabel(i.color, 'right');
          }
        } else {
          i.type = 'bar';
          i.yAxisIndex = 1;
          i.color = colors[2];
          i.label = seriesLabel(i.color, 'top');
        }
      });

      const options = {
        colors: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        grid: {
          left: '80',
          right: '80',
          bottom: '80',
        },
        legend: {
          data: series.map((i) => i.name),
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true,
            },
            data: date,
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '累计时长',
            min: 0,
            position: 'left',
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0],
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            name: '直播场次',
            min: 0,
            position: 'right',
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[2],
              },
            },
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        dataZoom: [
          {
            type: 'slider',
            show: true,
            xAxisIndex: [0],
            bottom: 20,
            start: 0,
            end: 100,
          },
          {
            type: 'slider',
            show: true,
            yAxisIndex: [0],
            right: 20,
            start: 0,
            end: 100,
          },
          {
            type: 'inside',
            xAxisIndex: [0],
            start: 0,
            end: 100,
          },
          {
            type: 'inside',
            yAxisIndex: [0],
            start: 0,
            end: 100,
          },
        ],
        series,
      };

      this.chart.setOption(options);
      this.chart.hideLoading();
    },

    saveImage() {
      const pixelRatio = Math.ceil(window.devicePixelRatio) || 1;
      const dataUrl = this.chart.getDataURL({type: 'jpg', pixelRatio});
      Common.downloadFile(dataUrl);
    },
  },
};
</script>

<style scoped lang="scss">
.line-bar {
  width: 100%;
  height: 500px;
}
</style>
