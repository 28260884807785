<template>
  <div class="statistics-card flex flex-column">
    <div class="block flex big">
      <span>{{ title }}</span>
      <span>{{ val }}</span>
      <span>{{ unit }}</span>
    </div>
    <div v-if="value.subVal !== undefined && value.subVal !== null" class="block flex small">
      <span>{{ subTitle }}</span>
      <span>{{ subVal }}</span>
      <span>{{ subUnit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatisticsCard',
  props: {
    value: {type: Object, default: () => ({})},
  },
  computed: {
    title() {return this.value?.title || '';},
    val() {return this.value?.val || 0;},
    unit() {return this.value?.unit || '';},

    subTitle() {return this.value?.subTitle || '';},
    subVal() {return this.value?.subVal || 0;},
    subUnit() {return this.value?.subUnit || '';},
  },
};
</script>

<style scoped lang="scss">
.statistics-card {
  min-width: 280px;
  width: 360px;
  height: 80px;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0 2px 7px 1px rgba(45, 46, 49, 0.1);

  .big {
    font-size: 16px;
    font-weight: 600;
  }

  .big + .small {
    margin-top: 6px;
  }
}
</style>
